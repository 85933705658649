<template>
  <div class="info-order__flex-col">
    <InfoTextBlock label-text="№ счета" :text="order.account_number"/>
    <InfoTextBlock
      label-text="Компания / Контакт"
      :text="order.client_name"
    />
    <InfoTextBlock label-text="Город" :text="order.city"/>
    <InfoTextBlock
      label-text="Тип заказа"
      :text="order.type && order.type.name"
    />
    <InfoTextBlock
      label-text="Область применения"
      :text="order.application_area_d && order.application_area_d.name"
    />
    <InfoTextBlock
      label-text="Назначение пружины"
      :text="order.appointment"
    />

    <OrderRelationOrders :order="order"/>
  </div>
</template>

<script>
import InfoTextBlock from '@/components/ui/AppInfoTextBlock.vue'
import { mapGetters } from 'vuex'
import OrderRelationOrders from '@/components/production/order/OrderRelationOrders.vue'

export default {
  name: 'OrderInfoHead',
  components: {
    OrderRelationOrders,
    InfoTextBlock
  },
  computed: {
    ...mapGetters({
      order: 'orderDetail/order'
    })
  }
}
</script>
