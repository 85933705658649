<template>
  <tr>
    <td
      v-for="(col, i) in columns"
      :key="i + 'td'"
      :id="`consignment-card--${consignment.id}`"
    >
      <OrderDateStatus v-if="col.name === 'positions.orders.date_finished'" :order="consignment.order" :in-cons-table="true" />
      <span
        v-else
        v-html="getValue(col)"
      />
    </td>

    <td style="min-width: 40px">
      <!--   Для последних трёх элементов, модалку выводим наверх   -->
      <DropdownMenu :show-items-top="itemsKey > 1 && itemsKey > itemsLength - 4">
        <VButton
          text="След. этап"
          icon="arrow-next"
          size="sm"
          :disabled="isLastStage"
          :loading="isLoadingStageLine"
          @click.native="openModalStageNext"
        />

        <router-link :to="{ name: 'DetailedConsignment', params: { id_cons: consignment.id } }">
          <VButton
            size="sm"
            icon="info"
            text="Информация"
            @click.native="goToConsignment"
          />
        </router-link>

        <VButton
          v-if="!hasExecutor && permissionUpdateConsignmentExecutor && visibleStageExecutors"
          size="sm"
          icon="set-executor"
          text="Назначить исполнителя"
          @click.native="openModal('add-executor')"
        />

        <VButton
          v-if="permissionUpdateConsignment && !this.isLastStage && !this.isLastButOneStage"
          size="sm"
          icon="edit"
          text="Редактировать"
          @click.native="openModal('update')"
        />

        <VButton
          v-if="permissionPrintDocumentProduction"
          size="sm"
          icon="print"
          text="На печать"
          @click.native="openModal('print')"
        />
        <VButton
          v-if="permissionDestroyConsignment && isFirstStage"
          size="sm"
          icon="remove"
          text="Удалить"
          @click.native="openModal('remove')"
        />
      </DropdownMenu>
      <ModalConsignmentRemove
        v-if="isModal === 'remove'"
        :consignment-id="consignment.id"
        @remove="removeHandler"
        @reject="closeModal"
      />

      <ModalConsignmentUpdate
        v-if="isModal === 'update'"
        :consignment="consignment"
        @updateHandler="updateHandler"
        @reject="closeModal"
      />

      <ModalConsignmentAddExecutor
        v-if="isModal === 'add-executor'"
        :consignment-id="consignment.id"
        @addExecutorHandler="addExecutorHandler"
        @reject="closeModal"
      />

      <ModalConsignmentSplit
        v-if="isModal === 'stage-next'"
        :consignment="consignment"
        :isFirstStage="isFirstStage"
        :stage-line="modalStageNext.stageLine"
        @goStageNext="goStageNext"
        @goSplit="goSplit"
        @reject="closeModal"
      />

      <ModalConsignmentSplitPrint
        v-if="isModal === 'split-print'"
        @goSplitPrint="goSplitPrint"
        :consignment-id="consignment.id"
        :consignment-split-id="getSplitConsignmentId"
        @request="openModalPreview"
      />

      <ModalConsignmentPrint
        v-if="isModal === 'print'"
        :consignment-id="consignment.id"
        :is-inner-reclamation="consignment.is_inner_reclamation"
        @close="closeModal"
        @request="openModalPreview"
      />

      <ModalFilesPreview
        v-if="isModal === 'files_preview'"
        :files="files"
        @close="closeModalPreview"
      />
    </td>
  </tr>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import DropdownMenu from '@/components/ui/DropdownMenu.vue'
import ConsignmentController from '@/controllers/production/Consignment/ConsignmentController'
import { mapGetters } from 'vuex'
import { mixinModal } from '@/mixins/modal/mixinModal'
import ModalConsignmentRemove from '@/components/ui/Modal/Production/Consignment/ModalConsignmentRemove.vue'
import ModalConsignmentSplitPrint from '@/components/ui/Modal/Production/Consignment/ModalConsignmentSplitPrint.vue'
import ModalConsignmentPrint from '@/components/ui/Modal/Production/Consignment/ModalConsignmentPrint.vue'
import ModalConsignmentSplit from '@/components/ui/Modal/Production/Consignment/ModalConsignmentSplit.vue'
import ModalConsignmentUpdate from '@/components/ui/Modal/Production/Consignment/ModalConsignmentUpdate.vue'
import ModalConsignmentAddExecutor from '@/components/ui/Modal/Production/Consignment/ModalConsignmentAddExecutor.vue'
import OrderDateStatus from '@/components/production/order/OrderDateStatus.vue'
import ModalFilesPreview from '@/components/files/ModalFilesPreview.vue'

export default {
  components: {
    ModalFilesPreview,
    OrderDateStatus,
    ModalConsignmentSplit,
    ModalConsignmentPrint,
    ModalConsignmentSplitPrint,
    ModalConsignmentRemove,
    ModalConsignmentUpdate,
    ModalConsignmentAddExecutor,
    DropdownMenu,
    VButton
  },
  mixins: [
    mixinModal
  ],

  props: {
    consignment: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    itemsLength: {
      type: Number
    },
    itemsKey: {
      type: Number
    }
  },
  data () {
    return {
      isLoadingStageLine: false,
      modalStageNext: {
        stageLine: []
      },
      consignmentSplit: {},
      modalHandlers: {
        setLoading: (bool) => this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', bool),
        close: this.closeModal
      },

      // preview files print
      files: []
    }
  },

  computed: {
    ...mapGetters('permission', {
      permissionPrintDocumentProduction: 'permissionPrintDocumentProduction',
      permissionUpdateConsignment: 'permissionUpdateConsignment',
      permissionUpdateConsignmentStageNext: 'permissionUpdateConsignmentStageNext',
      permissionUpdateConsignmentWeight: 'permissionUpdateConsignmentWeight',
      permissionUpdateConsignmentExecutor: 'permissionUpdateConsignmentExecutor',
      permissionDestroyConsignment: 'permissionDestroyConsignment'
    }),

    isConsignmentReclamation () {
      return this.consignment.inner_reclamations?.length
    },

    isFirstStage () {
      return this.consignment.status.id === this.consignment.statuses_line?.statuses[0].status_id
    },

    isLastStage () {
      return (this.consignment.status.id === this.consignment.statuses_line?.statuses.at(-1)?.status_id)
        ||
        (this.consignment?.status?.id === this.consignment.statuses_line?.statuses.at(-1)?.status?.id)
    },

    isLastButOneStage () {
      return (this.consignment.status.id === this.consignment.statuses_line?.statuses.at(-2)?.status_id)
        ||
        (this.consignment?.status?.id === this.consignment.statuses_line?.statuses.at(-2)?.status?.id)
    },

    getSplitConsignmentId () {
      return this.consignmentSplit?.id || null
    },

    hasExecutor () {
      return this.consignment.executor
    },

    visibleStageExecutors () {
      switch (this.consignment.status?.flag_id) {
        case 5:
        case 4:
        case 3: return false
        default: return true
      }
    }
  },

  methods: {
    goStageNext (data) {
      this.closeModalStageNext()
      this.$emit('goStageNext', data)
    },

    goSplit (data) {
      this.consignmentSplit = data
      this.$emit('goSplit', data)
      this.closeModalSplit()
      this.openModal('split-print')
    },

    goSplitPrint () {
      this.$emit('goSplitPrint', this.consignmentSplit)
    },

    addExecutorHandler (data) {
      this.closeModal()
      this.$emit('addExecutor', data)
    },

    async openModalStageNext () {
      if (this.isConsignmentReclamation && this.consignment.inner_reclamations[0].type === 'rework') {
        await this.setModalStageNextStageLine()
      }

      this.openModal('stage-next')
    },

    closeModalStageNext () {
      this.closeModal()
      this.modalStageNext.stageLine = []
    },

    closeModalSplit () {
      this.closeModal()
      this.modalStageNext.stageLine = []
    },

    async setModalStageNextStageLine () {
      let stageLine = []

      if (this.stageLine?.length) {
        stageLine = this.stageLine
      } else {
        try {
          this.isLoadingStageLine = true
          const response = await ConsignmentController.getStageLine(this.consignment.id)
          if (response?.status !== 200) return

          stageLine = response.data
        } finally {
          this.isLoadingStageLine = false
        }
      }

      this.modalStageNext.stageLine = stageLine
    },

    removeHandler () {
      const data = {
        id: this.consignment?.id,
        value: this.consignment?.value,
        article: this.consignment?.position?.article || ''
      }

      this.closeModal()
      this.$emit('remove', data)
    },

    getChildItemValue (keys) {
      if (keys?.categoryItemSecond) {
        return (this.consignment[keys.category] && this.consignment[keys.category][keys.categoryItem] && this.consignment[keys.category][keys.categoryItem][keys.categoryItemSecond])
          ? this.consignment[keys.category][keys.categoryItem][keys.categoryItemSecond]
          : '-'
      }

      return (this.consignment[keys.category] && this.consignment[keys.category][keys.categoryItem])
        ? this.consignment[keys.category][keys.categoryItem]
        : '-'
    },

    getValue (col) {
      let value
      if (!col.isChildItem) {
        value = this?.consignment[col.name] ?? '-'
      } else {
        value = col.childKeys ? this.getChildItemValue(col.childKeys) : '-'
      }
      return col.handlerFormat ? col.handlerFormat(value) : value
    },

    updateHandler (data) {
      this.$emit('updateHandler', data, this.modalHandlers)
    },

    async goToConsignment () {
      // await goToPage('DetailedConsignment', { id_cons: this.consignment.id })
    },

    openModalPreview (file) {
      this.files.length = 0
      this.files.push(file)
      this.openModal('files_preview')
    },

    closeModalPreview () {
      this.files = []
      this.closeModal()

      if (this.consignmentSplit?.id) {
        this.$emit('goSplitPrint', this.consignmentSplit)
      }
    }
  }
}
</script>
