<template>
  <div class="table" ref="container">
    <table
      :cols="columns.length"
    >
      <thead class="table__head">
      <tr>
        <th
          v-for="(col, idx) in cols"
          :key="idx"
          :colspan="col.colspan || 1"
          :rowspan="col.rowspan || 1"
          :nowrap="col.nowrap"
        >
            <span
              v-if="col.sortable"
              class="th--sort"
              :class="{'reverse': col.sortDesc}"
              @click="sortedHandler(col)"
            >
              <span class="mr-8">{{ col.title }}</span>

              <svgicon
                icon="arrow"
                width="13"
                height="8"
              />
            </span>

          <span v-else>{{ col.title }}</span>
        </th>
        <th style="min-width: 40px;width: 40px;">
          <VButton
            size="sm"
            class="btn--no-shadow"
            icon="reset-sort"
            @click.native="resetSort"
          />
        </th>
      </tr>
      </thead>

      <tbody class="table__body">
          <ConsignmentTableLine
            v-for="(item, i) in items"
            :key="i + 'tr'"
            :consignment="item"
            :columns="columns"
            :items-length="items.length"
            :items-key="i"
            @goStageNext="goStageNext"
            @goSplitPrint="goSplitPrint"
            @remove="remove"
            @updateHandler="updateHandler"
            @addExecutor="addExecutorHandler"
          />
      </tbody>
    </table>

    <Spinner v-if="loading" />

    <slot name="placeholder" v-if="!items.length && !loading" >
      <div class="no-records-placeholder h3">
        Нет записей
      </div>
    </slot>
  </div>
</template>

<script>
import ConsignmentTableLine from './ConsignmentTableLine.vue'
import VButton from '@/components/simple/button/VButton.vue'

export default {
  name: 'ConsignmentTable',
  components: {
    VButton,
    ConsignmentTableLine
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    // Для преобразования columns, если head из нескольких строк
    handlerColumns: {
      type: Function,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    rowActive: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      mTop: 0
    }
  },
  computed: {
    cols () {
      if (!this.handlerColumns) return this.columns
      return this.handlerColumns(this.columns)
    }
  },

  mounted () {
    this.mTop = this.$refs.container.getBoundingClientRect().top
  },
  methods: {
    sortedHandler (col) {
      this.$emit('sorted', col)
    },

    rowClick (item) {
      this.$emit('rowClick', item)
    },

    goSplitPrint (data) {
      this.$emit('goSplitPrint', data)
    },

    goStageNext (data) {
      this.$emit('goStageNext', data)
    },

    updateHandler (data, modalHandlers) {
      this.$emit('update', data, modalHandlers)
    },

    remove (data) {
      this.$emit('remove', data)
    },

    addExecutorHandler (data) {
      this.$emit('addExecutor', data)
    },

    goToConsignment (id) {
      this.$emit('goToConsignment', id)
    },

    resetSort () {
      this.$emit('resetSort')
    }
  }
}
</script>
