<template>
  <VForm
    v-model="formData"
    ref="positionFormRef"
  >
    <div v-if="(isType1 || isType2) && !isCatalog" class="position v-form--grid custom-scroll mb-20">
        <div class="position__item col-1" style="order: 1">
            <VSelect
                label="Вид пружины"
                name="shape"
                :style="isMobile ? 'max-width: 425px' : ''"
                :options="isType1 ? shapeAll : shapeType2"
                :disabled="isDisabled"
                :required="isRequired"
            >
                <Validation for="required"/>
            </VSelect>
        </div>
    </div>

    <div
        v-if="(!(isType1 || isType2) || formData.shape) || isCatalog"
        class="position v-form--grid custom-scroll"
    >

        <!--1 строка-->
        <div class="position__item">
          <VSelect
            label="Материал"
            name="material"
            :options="propertiesAll.material"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
          >
            <Validation for="required"/>
          </VSelect>
        </div>

        <!--2 строка-->
        <div class="position__item">
          <VInput
              label="d проволоки"
              name="d_wire"
              :disabled="isDisabled || isDisabledForProcessEngineer"
              :required="isRequired"
              :pattern="pattern.num100"
          >
              <Validation for="required" />
              <Validation for="pattern" key-text="num100"/>
          </VInput>
        </div>

        <!--3 строка-->
        <div class="position__item">
          <VInput
            v-if="visibleDInner"
            label="D внутренний"
            name="d_inner"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
          >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
          </VInput>

          <InputCopy
            v-if="visibleDInner"
            label="Допуск по D внутр."
            name="d_inner_limit"
            :model-copy="calcDiameterLimit"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
          />

          <VInput
            v-if="visibleDOutput"
            :label="isType127 && isShape ? 'D1 больший' : 'D наружный'"
            name="d_outer_1"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
          >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
          </VInput>

          <InputCopy
            v-if="visibleDOutput"
            :label="isType127 && isShape? 'Допуск по D1 большему' : 'Допуск по D наруж.'"
            name="d_outer_1_limit"
            :model-copy="calcDiameterLimit"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
          />

          <VInput
            v-if="isType4"
            label="Габаритная длина изделия"
            name="overall_length"
            :disabled="isDisabled"
            :required="isRequired"
          >
            <Validation for="required"/>
          </VInput>
        </div>

        <!--4 строка-->
        <div class="position__item">
          <VInput
              v-if="isType127 && isShape"
              label="D1 меньший"
              name="d_outer_2"
              :disabled="isDisabled || isDisabledForProcessEngineer"
              :required="isRequired"
              :pattern="pattern.num100"
          >
              <Validation for="required"/>
              <Validation for="pattern" key-text="num100"/>
          </VInput>

          <InputCopy
            v-if="isType127 && isShape"
            label="Допуск по D1 меньшему"
            name="d_outer_2_limit"
            :model-copy="calcDOuterLimit2"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
          />

          <VInput
            v-if="isType4"
            label="Допуск на линейные размеры"
            name="linear_size_limit"
            :disabled="isDisabled"
            :pattern="pattern.limitNum100"
          >
            <Validation for="pattern" key-text="limitNum100"/>
          </VInput>
        </div>

        <!--5 строка-->
        <div class="position__item">
          <VInput
            v-if="notType4"
            :label="isType127 ? 'Индекс (i) 1' : 'Индекс (i)'"
            name="index"
            :width="isType127 && isShape ? '50%' : ''"
            disabled
          />
          <VInput
            v-if="isType127 && isShape"
            label="Индекс (i) 2"
            name="index_2"
            width="50%"
            disabled
          />
        </div>

        <!--6 строка-->
        <div class="position__item">
          <VSelect
            v-if="notType48"
            label="Направление навивки"
            name="winding_dir"
            :options="propertiesAll.winding_direction"
            :disabled="isDisabled"
            :required="isRequired && !isType5"
          >
            <Validation for="required" v-if="!isType5"/>
          </VSelect>
        </div>

        <!--7 строка-->
        <div class="position__item">
          <VInput
              v-if="notType48"
              label="Длина тела (L0)"
              name="body_length"
              :disabled="isDisabled || isDisabledForProcessEngineer"
              :required="isRequired"
              :pattern="pattern.num100"
          >
              <Validation for="required"/>
              <Validation for="pattern" key-text="num100"/>
          </VInput>

          <InputCopy
            v-if="notType48 && notType2"
            label="Допуск по длине"
            name="body_length_limit"
            :model-copy="calcBodyLengthLimit"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern-key="!this.isType35 ? 'limitNum100' : ''"
          />

          <VInput
              v-if="isType8"
              label="Зазор"
              name="gap"
              :disabled="isDisabled"
              :required="isRequired"
              :pattern="pattern.num100"
          >
              <Validation for="required"/>
              <Validation for="pattern" key-text="num100"/>
          </VInput>

          <InputCopy
            v-if="isType8"
            label="Допуск на зазор"
            name="gap_limit"
            :model-copy="calcClearanceTolerance"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
          />
        </div>

        <!--8 строка-->
        <div class="position__item">
          <InputCopy
            v-if="visiblePerpendicularity"
            label="Перпендикулярность, ⊥"
            name="perpendicularity"
            :model-copy="calcPerpendicularity"
            :disabled="isDisabled"
            :required="isRequired"
          />
        </div>

        <!--9 строка-->
        <div class="position__item">
          <VSelect
            v-if="isType17"
            label="Опорный виток 1"
            name="reference_turn_1"
            :options="propertiesAll.reference_turn"
            :disabled="isDisabled"
            :required="isRequired"
          >
            <Validation for="required"/>
          </VSelect>

          <VSelect
            v-if="isType17"
            label="Опорный виток 2"
            name="reference_turn_2"
            :options="propertiesAll.reference_turn"
            :disabled="isDisabled"
            :required="isRequired"
          >
            <Validation for="required"/>
          </VSelect>
        </div>

        <!--10 строка-->
        <div class="position__item">
          <VInput
            v-if="isType7"
            label="Число витков на цилиндр"
            name="number_turn_cylinder"
            :lengthTooltip="5"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern="pattern.num100"
          >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
          </VInput>

          <VInput
            v-if="isType7"
            label="Число витков на конус"
            name="number_turn_cone"
            :disabled="isDisabled"
            :pattern="pattern.num100"
          >
            <Validation for="pattern" key-text="num100"/>
          </VInput>
        </div>

        <!--11 строка-->
        <div class="position__item">
          <VInput
            v-if="isType12359"
            label="Число рабочих витков (n)"
            name="number_turns"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
          >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
          </VInput>
          <VInput
            v-if="isType178"
            label="Число полных витков (n1)"
            name="number_full_turns"
            :disabled="isDisabled || isDisabledForProcessEngineer"
            :required="isRequired"
            :pattern="pattern.num100"
          >
            <Validation for="required" />
            <Validation for="pattern" key-text="num100"/>
          </VInput>
        </div>

        <!--12 строка-->
        <div class="position__item">
          <InputCopy
            v-if="visibleTurnsLimit"
            label="Допуск по виткам"
            name="turns_limit"
            :model-copy="calcTurnsLimit"
            :disabled="isDisabled"
            :required="isRequired"
          />
        </div>

        <!--13 строка-->
        <div class="position__item">
          <VInput
              v-if="isType2"
              label="Длина по зацепам"
              name="length_by_hooks"
              :disabled="isDisabled"
              :required="isRequired"
              :pattern="pattern.num100"
          >
              <Validation for="required" />
              <Validation for="pattern" key-text="num100"/>
          </VInput>
          <InputCopy
            v-if="isType2"
            label="Допуск по длине"
            name="body_length_limit"
            :model-copy="calcBodyLengthLimit"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern-key="!this.isType35 ? 'limitNum100' : ''"
          />
        </div>

        <!--14 строка-->
        <div class="position__item">
          <VInput
            v-if="isType359"
            label="Длина отвода 1"
            name="tap_length_1"
            :disabled="isDisabled"
            :required="isRequired && isType35"
          >
            <Validation for="required" v-if="isType35"/>
          </VInput>

          <InputCopy
            v-if="isType359"
            label="Допуск на длину отвода 1"
            name="tap_length_1_limit"
            :model-copy="calcTapLengthLimit1"
            :disabled="isDisabled"
            :required="isRequired && isType35"
            :pattern-key="!this.isType35 ? 'limitNum100' : ''"
          />

          <VSelect
            v-if="isType2"
            label="Тип зацепа 1"
            name="hook_type_1"
            :options="propertiesAll.hook_type"
            :disabled="isDisabled"
            :required="isRequired"
          >
            <Validation for="required"/>
          </VSelect>

          <VInput
            v-if="isType2"
            label="Длина зацепа 1"
            name="hook_length_1"
            :required="isRequired"
            :disabled="isDisabled"
          >
            <Validation for="required"/>
          </VInput>

          <VSelect
            v-if="isType8"
            label="Плоскостность"
            name="flatness"
            :options="propertiesAll.flatness"
            :disabled="isDisabled"
            :required="isRequired"
          >
            <Validation for="required"/>
          </VSelect>
        </div>

        <!--15 строка-->
        <div class="position__item">
          <VInput
              v-if="isType359"
              label="Длина отвода 2"
              name="tap_length_2"
              :disabled="isDisabled"
              :required="isRequired && isType35"
          >
              <Validation for="required" v-if="isType35"/>
          </VInput>

          <InputCopy
            v-if="isType359"
            label="Допуск на длину отвода 2"
            name="tap_length_2_limit"
            :model-copy="calcTapLengthLimit2"
            :disabled="isDisabled"
            :required="isRequired && isType35"
            :pattern-key="!this.isType35 ? 'limitNum100' : ''"
          />

          <VSelect
              v-if="isType2"
              label="Тип зацепа 2"
              name="hook_type_2"
              :options="propertiesAll.hook_type"
              :disabled="isDisabled"
              :required="isRequired"
          >
              <Validation for="required"/>
          </VSelect>

          <VInput
            v-if="isType2"
            label="Длина зацепа 2"
            name="hook_length_2"
            :required="isRequired"
            :disabled="isDisabled"
          >
            <Validation for="required"/>
          </VInput>

          <VSelect
              v-if="isType8"
              label="Фаска"
              name="chamfer"
              :options="propertiesAll.chamfer"
              :disabled="isDisabled"
              :required="isRequired"
          >
              <Validation for="required"/>
          </VSelect>
        </div>

        <!--16 строка-->
        <div class="position__item">
          <InputCopy
            v-if="isType17"
            label="Шаг (t)"
            name="step"
            :model-copy="calcStep"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="num100"
          />
          <InputCopy
            v-if="isType17"
            label="Допуск на шаг"
            name="step_limit"
            :model-copy="calcStepLimit"
            :disabled="isDisabled"
            :required="isRequired"
          />
        </div>

        <!--17 строка-->
        <div v-if="isType2 || isType5" class="position__item">
          <VSelect
              v-if="isType2"
              label="Ориентация зацепов"
              name="hook_orientation_1"
              :options="propertiesAll.hook_orientation"
              :disabled="isDisabled"
              :required="isRequired"
          >
              <Validation for="required"/>
          </VSelect>

          <VInput
              v-if="isType5"
              label="Длина зацепа"
              name="hook_length_1"
              :disabled="isDisabled"
          />
        </div>

      <!--18 строка-->
      <div class="position__item">
        <VSelect
          v-if="isType35"
          label="Тип навивки"
          name="winding_type"
          :options="propertiesAll.winding_type"
          :required="isRequired"
          :disabled="isDisabled"
        >
          <Validation for="required"/>
        </VSelect>
      </div>

        <!--19 строка-->
        <div class="position__item">
          <VInput
              v-if="isType9"
              label="Ориентация отводов"
              name="tap_orientation"
              :disabled="isDisabled"
              :required="isRequired && isType35"
              :pattern="pattern.num"
          >
              <Validation for="required" v-if="isType35"/>
              <Validation for="pattern" key-text="num"/>
          </VInput>

          <VInput
              v-if="isType35"
              label="Угол м/у отводами и зацепом"
              name="tap_angle"
              :disabled="isDisabled"
              :required="isRequired"
              :pattern="pattern.num"
          >
              <Validation for="required"/>
              <Validation for="pattern" key-text="num"/>
          </VInput>
        </div>

        <!--20 строка-->
        <div class="position__item" >
          <VSelect
            v-if="isType8"
            label="Загибы"
            name="folds"
            :options="propertiesAll.fold"
            :disabled="isDisabled"
          >
            <Validation for="required"/>
          </VSelect>
        </div>

        <!--21 строка-->
        <div v-if="isType2 || isType7" class="position__item">
        <VInput
            v-if="isType2"
            label="Зазор зацепа 1"
            name="hook_gap_1"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern="pattern.num100"
        >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
        </VInput>

        <InputCopy
          v-if="isType2"
          label="Допуск на зазор 1"
          name="hook_gap_1_limit"
          :model-copy="calcClearanceTolerance2"
          :disabled="isDisabled"
          :required="isRequired"
          pattern-key="limitNum100"
        />

        <VInput
            v-if="isType5"
            label="Угол загиба на зацепе"
            name="hook_bend_angle"
            :disabled="isDisabled"
        />
        </div>

        <!--22 строка-->
        <div  v-if="isType2" class="position__item">
          <VInput
            v-if="isType2"
            label="Зазор зацепа 2"
            name="hook_gap_2"
            :disabled="isDisabled"
            :required="isRequired"
            :pattern="pattern.num100"
          >
            <Validation for="required"/>
            <Validation for="pattern" key-text="num100"/>
          </VInput>

          <InputCopy
            v-if="isType2"
            label="Допуск на зазор 2"
            name="hook_gap_2_limit"
            :model-copy="calcClearanceTolerance2"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="limitNum100"
          />
        </div>

        <!--23 строка-->
        <div class="position__item">
        <InputCopy
            label="Длина развертки"
            name="sweep_length"
            :model-copy="calcSweepLength"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="num100"
        />

        <VInput
            label="Масса, кг"
            v-model="calcWeight"
            disabled
        >
            <Validation for="pattern" key-text="num100"/>
            <Validation for="required"/>
        </VInput>
        </div>

        <!--24 строка-->
        <div class="position__item">
          <InputCopy
            label="Твердость"
            name="hardness"
            :model-copy="calcHardness"
            :disabled="isDisabled"
            :required="isRequired && !isType9"
          />
        </div>

        <!--25 строка-->
        <div class="position__item">
          <SelectTooltip
            label="Поверхностная обработка"
            name="surface_treatment"
            :options="propertiesAll.surface_treatment"
            :model-tooltip="calcSurfaceTreatment"
            :disabled="isDisabled"
            :required="isRequired"
          />
        </div>

        <!--26 строка-->
        <div class="position__item">
          <VSelect
              label="Испытания"
              name="tests"
              :options="propertiesAll.test"
              :disabled="isDisabled"
              :required="isRequired"
          >
              <Validation for="required"/>
          </VSelect>
        </div>

        <div class="position__item">
          <VInput
            v-if="visibleStiffness"
            label="Жесткость, Н/мм"
            name="stiffness"
            disabled
          />
        </div>

        <div class="position__item">
          <InputCopy
            v-if="hasProbes"
            label="Количество пробников"
            name="probes"
            :model-copy="calcProbes"
            :disabled="isDisabled"
            :required="isRequired"
            pattern-key="num"
          />
        </div>

        <!--27 строка-->
        <div class="position__item">
          <VSelect
              label="Покрытие"
              name="coating"
              :options="coatingOptionsFiltered"
              :disabled="isDisabled"
              :required="isRequired"
          >
              <Validation for="required"/>
          </VSelect>

          <VInput
              v-if="isDisabled && formData.color_text && !formData.color"
              label="Цвет"
              name="color_text"
              disabled
          />

          <VSelect
              v-else
              label="Цвет"
              name="color"
              :options="colorOptionsFiltered"
              searchable
              :disabled="isDisabled"
              :required="isRequired"
          >
              <Validation for="required"/>
          </VSelect>
        </div>

      <!--28 строка-->
      <div class="position__item relative">
        <VInput
          label="Комментарий для производства"
          type="textarea"
          name="comment"
          :disabled="isDisabled"
          :required="!isTemptate && !isCatalog"
        >
          <Validation for="required"/>
        </VInput>

        <div class="comment-buttons flex gap-xxs">
          <VButton
            v-if="!isDisabled && !isTemptate && !isCatalog"
            text="СЗ"
            icon="create"
            size="sm"
            @click.native="$emit('copyRelativeOrders')"
          />

          <VButton
            v-if="!isDisabled && !isTemptate && !isCatalog"
            text="ПП"
            icon="create"
            size="sm"
            @click.native="$emit('copyNoteManufacture')"
          />
        </div>
      </div>

      <!--29 строка-->
      <div class="position__item relative">
        <VInput
          label="Комментарий для ОТК"
          type="textarea"
          name="note_otk"
          :disabled="isDisabled"
          :required="!isTemptate && !isCatalog"
        >
          <Validation for="required"/>
        </VInput>

        <div class="comment-buttons flex gap-xxs">
          <VButton
            v-if="!isDisabled && !isTemptate && !isCatalog"
            text="ПО"
            icon="create"
            size="sm"
            @click.native="$emit('copyNoteOTK')"
          />
        </div>
      </div>
    </div>
  </VForm>
</template>

<script>
import VForm from '@/components/Form/VForm'
import VSelect from '@/components/Form/VSelect'
import VInput from '@/components/Form/Vinput/VInput'
import Validation from '@/components/Form/Validation'
import InputCopy from '@/components/Form/Vinput/InputCopy'
import VButton from '@/components/simple/button/VButton'
import SelectTooltip from '@/components/Form/SelectTooltip'

import { mixinFormData } from '@/mixins/form/mixinFormData'
import { mixinPositionType } from '@/mixins/views/Production/Position/mixinPositionType'
import { mixinPositionCalc } from '@/mixins/views/Production/Position/mixinPositionCalc'

import { mixinCoatingAndColorOptionsFiltered } from '@/mixins/views/mixinCoatingAndColorOptionsFiltered'
import { mixinPositionForm } from '@/mixins/views/Production/Position/mixinPositionForm'

export default {
  name: 'PositionFormDetailsMobile',
  components: {
    VForm,
    InputCopy,
    Validation,
    VInput,
    VSelect,
    VButton,
    SelectTooltip
  },
  mixins: [
    mixinFormData,
    mixinPositionType,
    mixinPositionCalc,
    mixinCoatingAndColorOptionsFiltered,
    mixinPositionForm
  ]
}
</script>

<style lang="scss">
.comment-buttons {
  position: absolute;
  top: -3px;
  right: 0;
}
</style>
