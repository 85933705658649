<template>
  <div class="info-order__flex-col">
    <InfoTextBlock
      label-text="Менеджер по продажам"
      :text="orderManager"
    />
    <InfoTextBlock
      label-text="Инженер технолог"
      :text="orderTechManager"
    />
    <InfoTextBlock
      label-text="Сроки изготовления"
      :text="order.timing ? order.timing.name : '-'"
    />
    <InfoTextBlock
      label-text="Дата отгрузки заказа"
      :text="order.date_finished | date('date')"
    />
    <InfoTextBlock
      :link="order.google_link"
      label-text="Диск"
      :text="order.google_link"
    />

    <InfoTextBlock
      v-if="order.amo_order_link"
      label-text="Заказ в CRM"
      :link="order.amo_order_link"
      :text="order.amo_order_link"
    />

    <InfoText
      v-if="order.from_outer_reclamation"
      label="Ссылка на рекламацию"
    >
      <router-link
        class="link-reset v-link hover--main"
        :to="{ name: $store.state.pages.reclamationPages.details, params: { id: order.from_outer_reclamation.id } }"
      >
        Рекламация
      </router-link>
    </InfoText>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InfoTextBlock from '@/components/ui/AppInfoTextBlock.vue'
import InfoText from '@/components/simple/text/InfoText.vue'
import { getUserFullName } from '@/utils/model/User'

export default {
  name: 'OrderInfo',
  components: {
    InfoText,
    InfoTextBlock
  },
  computed: {
    ...mapGetters({
      order: 'orderDetail/order'
    }),

    orderManager () {
      return getUserFullName(this.order.manager)
    },

    orderTechManager () {
      return getUserFullName(this.order.tech_manager)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
