<template>
  <VSelect
    :options="tabsList"
    :value="getSelectValue"
    :item-text="itemText"
    :allow-empty="false"
    not-search
    @change="onChange"
    name="navItem"
    style="max-width: 425px" />
</template>

<script>
import VSelect from '@/components/Form/VSelect.vue'

export default {
  components: {
    VSelect
  },
  props: {
    tabsList: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      default: 'name'
    },
    selectedItemIndex: {
      type: [String, Number],
      default: null
    }
  },

  computed: {
    getSelectValue () {
      if (this.selectedItemIndex != null) {
        return this?.tabsList?.[this.selectedItemIndex]
      }
      return null
    }
  },

  methods: {
    onChange (item) {
      this.$emit('onSelectTab', item)
    }
  }
}
</script>
