<template>
  <div class="stage-implement">
      <div class="stage-implement__header">
        <div class="flex jc-sb gap-s stage-implement__header-title">
          <h3 @click="isMobile && collapseAccordion()">Этапы выполнения</h3>

          <div class="buttons" v-if="isMobile">
            <VButton
              block="collapse"
              :class="{ open: isOpenAccordion }"
              @click.native="collapseAccordion"
              style="z-index: 2"
              :icon="{ name: 'arrow', dir: isOpenAccordion ? '0' : '180' }"
            />
          </div>
        </div>

        <div class="d-flex ai-c gap-l stage-implement__header-statuses">
          <div class="d-flex ai-c gap-xxs">
            <svgicon
              class="svg-icon--status-start"
              icon="status-start"
              width="23"
              height="24"
            />

            <span>Ожидает начала:&nbsp;</span>
            <span>{{consignmentsCount.waiting_start}}&nbsp;шт</span>
          </div>

          <div class="d-flex ai-c gap-xxs">
            <svgicon
              class="svg-icon--status-ready"
              icon="status-ready"
              width="27"
              height="26"
            />

            <span>Склад:&nbsp;</span>
            <span>{{consignmentsCount.from_warehouse}}&nbsp;шт</span>
          </div>

          <div class="d-flex ai-c gap-xxs">
            <svgicon
              class="svg-icon--status-shipped"
              icon="status-shipped"
              width="32"
              height="21"
            />

            <span>Отгружено:&nbsp;</span>
            <span>{{consignmentsCount.shipped}}&nbsp;шт</span>
          </div>
        </div>
      </div>

    <AccordionCard
      :is-open="!isMobile || isOpenAccordion"
    >
      <div class="stage-implement__body">
        <div
          class="stage-implement__column-wrap"
          v-for="(stage, i) in stageStore"
          :key="i + 'stage'"
        >
          <p class="stage-implement__column-title">{{ i+1 }}&nbsp;Этап</p>
          <div class="stage-implement__column">
            <div class="stage-implement__column-header">
              <p>{{ stage.name || 'Этап' }}</p>
              <p>{{ stage.listOfConsignment && sumQtyConsignments(stage.listOfConsignment) }}</p>
            </div>

            <div v-if="stage.listOfConsignment" class="stage-implement__column-body">
              <StageLineImplementCard
                v-for="(consignment, i) in stage.listOfConsignment"
                :key="i + 'consignment'"
                :stageImplement="consignment"
              />
            </div>
          </div>
        </div>
      </div>
    </AccordionCard>
  </div>
</template>

<script>
import StageLineImplementCard from '@/components/views/Production/OrderDetail/StageLineImplementCard'
import { mapGetters, mapState } from 'vuex'
import VButton from '@/components/simple/button/VButton.vue'
import AccordionCard from '@/components/accordionList/AccordionCard.vue'
import { mixinAccordion } from '@/mixins/mixinAccordion'

export default {
  name: 'StageLineImplementation',
  components: {
    AccordionCard,
    VButton,
    StageLineImplementCard
  },
  mixins: [mixinAccordion],
  computed: {
    ...mapGetters('orderDetail', {
      stageStore: 'stageStore',
      currentPosition: 'currentPositionActive'
    }),
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),
    consignmentsCount () {
      return {
        waiting_start: this.currentPosition?.waiting_start || 0,
        from_warehouse: this.currentPosition?.from_warehouse || 0,
        shipped: this.currentPosition?.shipped || 0
      }
    }
  },

  methods: {
    sumQtyConsignments (items) {
      let count = 0

      items.forEach(item => {
        if (item.value) {
          count += item.value
        }
      })

      return count
    }
  }
}
</script>

<style lang="scss" scoped>
.stage-implement {
  background: #FFFFFF;
  border: 1px solid $color__gray-blue;
  box-shadow: $color__card-box-shadow;
  border-radius: 20px;

  &__header {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $color__gray-blue;
    padding: 20px 30px;

    @media screen and (max-width: $tablet-width-s) {
      flex-direction: column;
      align-items: start;
      gap: var(--s);
      padding: var(--xs-mobile);
      margin-bottom: 0;
      border-bottom: none;

      &-title {
        width: 100%;
      }

      &-statuses {
        flex-direction: column;
        gap: var(--s);
        align-items: start;
      }
    }
  }

  &__flex {
    display: flex;
    align-items: center;
  }

  &__body {
    padding: 40px 60px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: $tablet-width-s) {
      padding: var(--s) var(--xs-mobile) var(--xs-mobile);
      border-top: 1px solid $color__gray-blue;
    }
  }

  &__column {
    border-radius: 20px;
    padding: 16px 16px 20px;
    background-color: var(--color__lavander);

    &-wrap {
      display: inline-block;
      min-width: 215px;
      max-width: 215px;
    }

    &-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: $color__cyan;
      text-align: center;
      margin-bottom: 8px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      p:first-child {
        font-weight: 500;
        font-size: 16px;
        color: $color__orange-dawn;
      }
      p:last-child {
        font-weight: 600;
        font-size: 14px;
        color: var(--color__pearl-dark-gray);
      }
    }
    &-body {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }

    @media screen and (max-width: $tablet-width-s) {

      &-wrap {
        flex: 1 0 30%;
        min-width: auto;
        max-width: none;
      }
      &-title {
        text-align: left;
      }
    }

    @media screen and (max-width: $mobile-width-l) {
      &-wrap {
        flex: 1 0 45%;
      }
    }

    @media screen and (max-width: $mobile-width) {
      &-wrap {
        flex: 1 0 100%;
      }
    }
  }
}
</style>
