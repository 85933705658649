<template>
  <div>
    <AppAccordion
      class="info-order box"
      :visible-btn="visibleOrderNote && isOrderTab && !isMobile"
      :mobile-open="isOpenAccordion"
    >
      <div slot="header">
        <div class="box__header">
          <div class="d-flex ai-c gap-m" :class="{ 'flex-col ai-n': isMobile }">
            <div v-if="isMobile">
              <h3 @click="collapseAccordion">Информация</h3>
            </div>
            <TabsSwiper
              v-if="!isMobile"
              class="order-info-tabs tabs-swiper--border"
              :space-between="0"
            >
              <TabItem
                class="swiper-slide"
                v-for="tab of tabs"
                :key="tab.id"
                :navItem="tab"
                :is-active="activeTabId === tab.id"
                @click.native="setActiveTab(tab.id)"
              />
            </TabsSwiper>

            <OrderReclamationExternal
              v-if="order.reclamations && order.reclamations.length"
              :reclamations="order.reclamations"
            />

            <OrderProbes
              v-if="order.probes && order.probes.length"
              :probes="order.probes"
            />
          </div>

          <div class="buttons" v-if="!isMobile">
            <PrintWaybill
              v-if="!isOrderTab && !isPackingTab && waybill && $store.getters['permission/permissionPrintWaybill']"
              :waybill="waybill"
            />

            <VButton
              v-if="isPackingTab && $store.getters['permission/permissionPrintPacking']"
              text="Печать"
              size="sm"
              icon="print"
              :disabled="!selectedRowsPacking.length"
              @click.native="openModal('print-packing')"
            />

            <VButton
              v-if="isPackingTab && $store.getters['permission/permissionDeletePacking']"
              text="Удалить"
              size="sm"
              icon="remove"
              :disabled="!selectedRowsPacking.length"
              @click.native="openModal('delete-packing')"
            />

            <VButton
              v-if="order.status_id > 3 && permissionOrderPickup && !isPackingTab"
              text="Готовность к отгрузке"
              size="sm"
              icon="pickup"
              @click.native="openModalReadyForShipment"
            />

            <VButton
              v-if="$store.getters['permission/permissionCreateWaybill'] && !isOrderTab && !isPackingTab"
              :class="!waybill && 'filter-gray'"
              text="Редактировать"
              size="sm"
              icon="edit"
              @click.native="toggleEdit"
            />

            <VButton
              v-if="order.deleted_at && $store.getters['permission/isAdmin']"
              text="Восстановить"
              size="sm"
              icon="restore"
              @click.native="openModal('restore')"
            />

            <VButton
              v-if="permissionOrderUpdate && isOrderTab"
              text="Редактировать"
              size="sm"
              icon="edit"
              @click.native="
                $router.push({
                  name: 'OrderUpdate',
                  params: { orderId: order.id }
                })
              "
            />
          </div>

          <div v-else class="buttons buttons--card-full">
            <DropdownMenu :dropdown-is-modal="isModal" @closeModal="closeModal" @openModal="openModal">
              <PrintWaybill
                v-if="!isOrderTab && waybill && $store.getters['permission/permissionPrintWaybill']"
                :waybill="waybill"
              />

              <VButton
                v-if="order.status_id > 3 && permissionOrderPickup"
                text="Готовность к отгрузке"
                size="sm"
                icon="pickup"
                @click.native="openModalReadyForShipment"
              />

              <VButton
                v-if="$store.getters['permission/permissionCreateWaybill'] && !isOrderTab"
                :class="!waybill && 'filter-gray'"
                text="Редактировать"
                size="sm"
                icon="edit"
                @click.native="toggleEdit"
              />

              <VButton
                v-if="order.deleted_at && $store.getters['permission/isAdmin']"
                text="Восстановить"
                size="sm"
                icon="restore"
                @click.native="openModal('restore')"
              />

              <VButton
                v-if="permissionOrderUpdate && isOrderTab"
                text="Редактировать"
                size="sm"
                icon="edit"
                @click.native="
                $router.push({
                  name: 'OrderUpdate',
                  params: { orderId: order.id }
                })
              "
              />
            </DropdownMenu>
            <VButton
              block="collapse"
              :class="{ open: isOpenAccordion }"
              @click.native="collapseAccordion"
              style="z-index: 2"
              :icon="{ name: 'arrow', dir: isOpenAccordion ? '0' : '180' }"
            />
          </div>
        </div>
        <TabsSelect
          v-if="isMobile"
          @onSelectTab="onSelectTab"
          class="tabs-select mt-20"
          item-text="mobileTitle"
          :selected-item-index="getActiveTabIndex"
          :tabs-list="tabs"
        />

        <div v-if="isOrderTab && !isMobile" class="info-order__flex">
          <OrderInfoHead />
          <OrderInfo />
          <div class="info-order__flex-col">
            <InfoTextBlock
              label-text="Причина просрочки заказа"
              :text="order.delay_reason && order.delay_reason.name"
            />

            <InfoTextBlock
              label-text="Примечание к причине просрочки"
              :text="order.note_production"
            />
          </div>
        </div>

        <WaybillCardDetails
          v-if="!isOrderTab && !isPackingTab && !isEdit && !isMobile"
          :waybill="waybill ? waybill : ''"
        />

        <WaybillEditForm
          v-if="!isOrderTab && !isPackingTab && isEdit"
          :order-id="order.id"
          :waybill="waybill"
          @update-success="turnOffIsEdit"
          @cancel="turnOffIsEdit"
        />

        <OrderInfoPackaging
          v-if="isPackingTab"
          @selectRowsPacking="selectRowsPacking"
        />
      </div>

      <div v-if="isOrderTab" class="info-order__note" :class="{ 'mt-20': isMobile }">
        <OrderInfoHead v-if="isMobile" />
        <OrderInfo v-if="isMobile" />
        <div v-if="isMobile" class="info-order__flex-col">
          <InfoTextBlock
            label-text="Причина просрочки заказа"
            :text="order.delay_reason && order.delay_reason.name"
          />

          <InfoTextBlock
            label-text="Примечание к причине просрочки"
            :text="order.note_production"
          />
        </div>

        <InfoTextBlock
          v-if="order.note_to"
          label-text="Примечание для тех. отдела"
          class="info--column"
          :text="order.note_to"
          :has-tooltip="false"
        />

        <InfoTextBlock
          v-if="order.note_manufacture"
          label-text="Примечание для производства"
          class="info--column"
          :text="order.note_manufacture"
          :has-tooltip="false"
        />

        <InfoTextBlock
          v-if="order.note_otk"
          label-text="Примечание для ОТК"
          class="info--column"
          :text="order.note_otk"
          :has-tooltip="false"
        />

        <InfoTextBlock
          v-if="order.note_technologist"
          label-text="Примечание для склада"
          class="info--column"
          :text="order.note_technologist"
          :has-tooltip="false"
        />
      </div>
      <WaybillCardDetails
        class="mt-20"
        v-if="!isOrderTab && !isEdit && isMobile"
        :waybill="waybill ? waybill : ''"
      />
    </AppAccordion>

    <ModalForm
      v-if="isModal === 'modal-shipment'"
      title="Редактировать заказ"
      only-btn-submit
      @request="submitReadyForShipment"
      @reject="closeModal"
    >
      <div class="modal-form__checkboxes-wrap column gap-xs">
        <VCheckbox
          v-model="isDocumentsAreReady"
          label="Документы готовы"
          name="is_documents_are_ready"
        />

        <VCheckbox v-model="isPickup" label="Самовывоз" name="is_pickup"/>
      </div>
    </ModalForm>

    <ModalOrderRestore v-if="isModal === 'restore' && $store.getters['permission/isAdmin']" @close="closeModal" />

    <PrintPacking
      v-if="isModal === 'print-packing'"
      :items="[]"
      :order-id="order.id"
      :selected-rows="selectedRowsPacking"
      @close="closeModal"
    />

    <ModalConfirmation
      v-if="isModal === 'delete-packing'"
      title="Уверены что хотите удалить упаковку?"
      @confirm="packingDeleteHandler"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import AppAccordion from '@/components/ui/AppAccordion'
import InfoTextBlock from '@/components/ui/AppInfoTextBlock'
import ModalForm from '@/components/ui/Modal/ModalForm'
import VButton from '@/components/simple/button/VButton'
import VCheckbox from '@/components/Form/VCheckbox'
import { OrderReclamationExternal, OrderProbes } from '@/entities/order'
import TabItem from '@/components/ui/TabsSwiper/TabItem.vue'
import TabsSwiper from '@/components/ui/TabsSwiper/TabsSwiper.vue'
import { WaybillCardDetails } from '@/entities/waybill'
import { WaybillEditForm } from '@/features/waybill/edit'
import { PrintWaybill } from '@/features/waybill/print-waybill'

import { mapActions, mapGetters, mapState } from 'vuex'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { mixinIsEdit } from '@/mixins/mixinIsEdit'
import ModalOrderRestore from '@/components/production/order/ModalOrderRestore.vue'

import DropdownMenu from '@/components/ui/DropdownMenu.vue'
import TabsSelect from '@/components/ui/TabsSelect.vue'
import OrderInfo from '@/components/production/order/OrderInfo.vue'
import OrderInfoHead from '@/components/production/order/OrderInfoHead.vue'
import { mixinAccordion } from '@/mixins/mixinAccordion'
import OrderInfoPackaging from '@/components/production/order/OrderInfoPackaging.vue'
import PrintPacking from '@/features/packing/print-packing/ui/PrintPacking.vue'
import ModalConfirmation from '@/components/ui/Modal/ModalConfirmation.vue'
import getValuesArray from '@/utils/array/getValuesArray'

// const TABS = [
//   {
//     id: 1,
//     title: 'Информация по заказу'
//   },
//   {
//     id: 3,
//     title: 'Упаковка'
//   },
//   {
//     id: 2,
//     title: 'Информация по отгрузке'
//   }
// ]

export default {
  name: 'OrderInfoBlock',
  components: {
    OrderInfoHead,
    OrderInfo,
    TabsSelect,
    DropdownMenu,
    ModalConfirmation,
    PrintPacking,
    OrderInfoPackaging,
    ModalOrderRestore,
    TabsSwiper,
    TabItem,
    AppAccordion,
    InfoTextBlock,
    ModalForm,
    VButton,
    VCheckbox,
    WaybillCardDetails,
    WaybillEditForm,
    PrintWaybill,
    OrderReclamationExternal,
    OrderProbes
  },
  mixins: [mixinModal, mixinIsEdit, mixinAccordion],

  data () {
    return {
      isUpdate: false,
      isPickup: false,
      isDocumentsAreReady: false,
      activeTabId: 1,
      selectedRowsPacking: {
        type: Array,
        default: () => []
      },
      tabsDefault: [
        {
          id: 1,
          title: 'Информация по заказу'
        },
        {
          id: 3,
          title: 'Упаковка',
          countPacking: null,
          bgCount: true
        },
        {
          id: 2,
          title: 'Информация по отгрузке'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      order: 'orderDetail/order',
      currentUserRole: 'currentUserRole',
      currentUserId: 'currentUserId'
    }),
    ...mapGetters('permission', [
      'permissionUpdateOrder',
      'permissionUpdateOrderByRole',
      'permissionUpdateOrderMarkersByRole'
    ]),
    ...mapState('waybill', {
      waybill: 'waybill'
    }),
    ...mapState('mobile', {
      isMobile: 'isMobile'
    }),
    ...mapState('orderDetail', {
      packingCount: 'packingCount'
    }),

    permissionOrderUpdate () {
      if (this.permissionUpdateOrderByRole) return true

      if (this.permissionUpdateOrder) {
        return (
          this.userIsOrderCreatorOrManager ||
          this.userIsOrderPositionResponsible
        )
      }

      return false
    },
    permissionOrderPickup () {
      return (
        this.permissionUpdateOrderMarkersByRole ||
        (this.permissionUpdateOrder && this.userIsOrderCreatorOrManager)
      )
    },

    userIsOrderCreatorOrManager () {
      switch (this.currentUserId) {
        case this.order?.manager?.id:
        case this.order?.user?.id:
          return true
      }
      return false
    },
    userIsOrderPositionResponsible () {
      if (
        this.currentUserRole === 'production-manager' &&
        this.order?.positions?.length
      ) {
        for (const position of this.order.positions) {
          if (this.currentUserId === position.responsible_id) {
            return true
          }
        }
      }
      return false
    },

    visibleOrderNote () {
      return (
        !!this.order.note_to ||
        !!this.order.note_manufacture ||
        !!this.order.note_otk ||
        !!this.order.note_technologist
      )
    },

    isOrderTab () {
      return this.activeTabId === this.tabs[0].id
    },

    isPackingTab () {
      return this.activeTabId === this.tabs[1].id
    },

    tabs () {
      const tabs = this.tabsDefault
      tabs[1].countPacking = this.packingCount
      return tabs
    },

    getActiveTabIndex () {
      let index = null
      this.tabs.forEach((elem, key) => {
        if (elem.id === this.activeTabId) {
          index = key
        }
      })

      return index
    }
  },

  created () {
  },

  destroyed () {
    this.$store.commit('waybill/RESET_STATE')
  },

  methods: {
    ...mapActions('orderDetail', [
      'updateOrderMarkers',
      'restoreOrder',
      'packingMassDelete'
    ]),

    async submitReadyForShipment () {
      this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

      await this.updateOrderMarkers({
        id: this.order.id,
        is_pickup: this.isPickup,
        documents_are_ready: this.isDocumentsAreReady,
        callback: this.closeModal
      })
      this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
    },

    openModalReadyForShipment () {
      this.syncModalReadyForShipmentFormData()
      this.openModal('modal-shipment')
    },

    syncModalReadyForShipmentFormData () {
      this.isPickup = this.order.is_pickup
      this.isDocumentsAreReady = this.order.documents_are_ready
    },

    setActiveTab (id) {
      this.activeTabId = id
    },

    onSelectTab (item) {
      this.setActiveTab(item.id)
    },

    selectRowsPacking (val) {
      this.selectedRowsPacking = val
    },

    // Packing
    async packingDeleteHandler () {
      try {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL')

        const data = {
          ids: getValuesArray(this.selectedRowsPacking)
        }
        await this.packingMassDelete(data)
      } finally {
        this.$store.commit('SET_IS_SENDING_REQUEST_MODAL', false)
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss">
.info-order {
  &__flex {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: var(--s);

    @media (min-width: 1100px) {
      display: grid;
      gap: 50px;
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $tablet-width-s) {
      margin-bottom: 0;
    }

    &-col {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  &__note {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
  }
}

.order-info-tabs {
  .tab-item:not(:last-child) {
    margin-right: 30px;
  }

  .tabs-swiper__container {
    margin-right: 0;
  }

  .tab-item__text {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;

    @media screen and (max-width: $tablet-width-s) {
      @include font16;
    }
  }

  .tabs-swiper__btn {
    display: none;
  }
}

.tabs-select {
  min-width: 425px;

  @media screen and (max-width: 500px) {
    min-width: auto;
  }
}
</style>
